import React from 'react';
import { Helmet } from 'react-helmet';

export default function Main({children}) {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IFRSys - Simplificando e Disseminando o Conhecimento Contábil</title>
        <meta name="description" content="É uma plataforma online que auxilia na gestão das áreas contábil e fiscal de empresas" />
        <link rel="canonical" href="https://ifrsys.com.br/" />
        <link rel="icon" type="image/png" href="images/gatsby-icon.png" />
      </Helmet>
      {children}
    </main>
  );
}
